'use client';

import { atom } from 'jotai';

import { atomWithStorage } from 'jotai/utils';
import Cookies from 'js-cookie';

const cookieStorage = {
  getItem: (key: string) => {
    const cookieValue = Cookies.get(key);
    return cookieValue ? JSON.parse(cookieValue) : null;
  },
  setItem: (key: string, value: string[]) =>
    Cookies.set(key, JSON.stringify(value), { expires: 300 }),
  removeItem: (key: string) => Cookies.remove(key),

  subscribe(key: string, callback: (value: any) => void, initialValue: any) {
    let currentValue = this.getItem(key) ?? initialValue;
    callback(currentValue);

    const checkForChanges = () => {
      const newValue = this.getItem(key);
      if (JSON.stringify(newValue) !== JSON.stringify(currentValue)) {
        currentValue = newValue;
        callback(currentValue);
      }
    };

    // Check for changes when the page becomes visible
    document.addEventListener('visibilitychange', checkForChanges);
    window.addEventListener('focus', checkForChanges);
    window.addEventListener('blur', checkForChanges);

    // Return an unsubscribe function
    return () => {
      document.removeEventListener('visibilitychange', checkForChanges);
      window.removeEventListener('focus', checkForChanges);
      window.removeEventListener('blur', checkForChanges);
    };
  },
};

export const favouritesAtom = atomWithStorage(
  `favourites-${Cookies.get('locale')}`,
  [],
  cookieStorage,
);

export const removeFromFavouritesAtom = atom(null, (_, set, heycarId) => {
  set(favouritesAtom, (currentFavourites: string[]) =>
    currentFavourites.filter((vehicleId: string) => vehicleId !== heycarId),
  );
});

export const addToFavouritesAtom = atom(null, (_, set, favData: { heycarId: string }) => {
  set(favouritesAtom, (currentFavourites: string[]) => [
    ...(currentFavourites || []),
    favData.heycarId,
  ]);
});

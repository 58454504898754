import { useEffect, useRef, useState } from 'react';

import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { getClientUserId } from '@cookies/GetClientUserId';

import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { tenantAtom } from '@core/Atoms/Tenant/Tenant.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { currentVehicleIdAtom } from '@core/Atoms/Vehicle/CurrentVehicleId.atom';
import { isWindowDefined } from '@core/Constants/Window';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { useInitialMount } from '@core/Hooks/UseInitialMount';
import {
  createEventWithCallNumber,
  generateLeadTrackingData,
} from '@core/Tracking/LeadForms.tracking';
import { generatePhoneNumber } from '@core/Utils/GetPhoneNumber';
import { getSource } from '@core/Utils/Leads/BuildLeadPayload';
import { loadGoogleRecaptcha } from '@core/Utils/loadGoogleRecaptcha';
import Button from '@gds/Button/Button';
import { isMobileAppAtom } from '@mobile-app/Atoms/IsMobileApp.atom';
import { sendNativeMessage } from '@mobile-app/Utils/SendNativeMessage';
import { useModal } from '@modal/Hooks/UseModal';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { dealerNumberAtom } from 'Atoms/App/Pdp/DealerNumber.atom';
import { dealerNumberErrorAtom } from 'Atoms/App/Pdp/DealerNumberError.atom';
import { leadFormIdAtom } from 'Atoms/App/Pdp/LeadFormId.atom';
import { showDealerNumberPdpAtom } from 'Atoms/App/Pdp/ShowDealerNumberPdp.atom';

import { CallBackModal } from '../CallBackModal/CallBackModal';
import useLeadFormHeaderTextExperiment from '../Hooks/useLeadFormHeaderTextExperiment';
import { LeadForm } from '../LeadForm/LeadForm';
import { StringsType } from '../LeadForm/LeadForm.entity';
import { StatusDisplay } from '../StatusDisplay/StatusDisplay';

import { callDealerCtasConfig, CtaPlacement } from './CallDealer.config';

import styles from './CallDealerForm.module.css';

import leadFormStyles from '../LeadForm/LeadForm.module.css';

export interface Props {
  strings: StringsType;
  locale: Locale;
  isSticky: boolean;
  isVsp?: boolean;
  isEcom: boolean;
  hasReserveNow?: boolean;
  ctaPlacement?: CtaPlacement;
  disabled?: boolean;
  shouldAutoGenNumber?: boolean;
}

export const CallDealerCTA = ({
  ctaPlacement = 'pdp_call-dealer',
  strings,
  locale,
  isSticky,
  isVsp,
  isEcom,
  hasReserveNow,
  disabled,
  shouldAutoGenNumber,
}: Props) => {
  const isInitialMount = useInitialMount();
  const vehicleId = useAtomValue(currentVehicleIdAtom);
  const vehicle = useAtomValue(currentVehicleAtom)!;
  const isMobileApp = useAtomValue(isMobileAppAtom)!;
  const userId = useAtomValue(userIdAtom);
  const formState = useAtomValue(formStateAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const [isNumberVisible, setIsNumberVisible] = useAtom(showDealerNumberPdpAtom);
  const [dealerNumber, setDealerNumber] = useAtom(dealerNumberAtom);
  const [dealerNumberError, setDealerNumberError] = useAtom(dealerNumberErrorAtom);
  const setLeadFormId = useSetAtom(leadFormIdAtom);
  const tenant = useAtomValue(tenantAtom);
  const [phoneNumber, setPhoneNumber] = useState({
    isLoading: false,
    number: '',
    isError: false,
  });
  const hasStartedAutoGenerateNo = useRef(false);
  const { openModal } = useModal();
  const isInvestorStockLayout = locale === 'uk' && tenant !== 'rac' && vehicle?.spec?.d2c && isEcom;
  const ctaConfig = callDealerCtasConfig[ctaPlacement];
  const ctaLocation = ctaPlacement === 'hov_call-dealer' ? 'hov' : 'pdp';
  const { header } = useLeadFormHeaderTextExperiment('request-callback', vehicle, strings);

  //should auto generate number
  useEffect(() => {
    if (!isInitialMount && !hasStartedAutoGenerateNo.current && shouldAutoGenNumber) {
      hasStartedAutoGenerateNo.current = true;
      handleClick();
    }
  }, [isInitialMount, hasStartedAutoGenerateNo, shouldAutoGenNumber]);

  useEffect(() => {
    if (!isInitialMount) {
      const { isError, number, isLoading } = phoneNumber;

      if (isError) {
        setDealerNumberError(true);

        if (ctaConfig.shouldShowRequestCallbackModal(window.innerWidth, isSticky)) {
          openModal(
            <CallBackModal handleCallbackClick={handleCallbackClick} messages={strings} />,
            {
              header,
              modalClassName: styles.modalContainer,
            },
          );
        }
      } else {
        if (number && !isLoading) {
          const query = window.matchMedia(`(max-width: 767px)`);
          if (query.matches) {
            window.open(`tel:${number}`, '_self');
          }
        }
      }
    }
  }, [phoneNumber]);

  const { callStart, callSubmit } = generateLeadTrackingData({
    searchParams,
    userId,
    formState,
    leadId: '',
    listingId: vehicleId,
    vehicle,
    leadFormId: 'call-dealer',
    ctaLocation,
    leadAction: 'start',
  });
  const facebookPixelValue = getClientSideCookieValue('_fbp');
  const facebookClickIdValue = getClientSideCookieValue('_fbc');

  const handleClick = async () => {
    if (isNumberVisible) {
      const callSubmitEvent = createEventWithCallNumber(
        vehicleId!,
        phoneNumber.number,
        ctaLocation,
      );
      trackCustomEvent({ event: callSubmitEvent!, context: callSubmit.context });
      window.open(`tel:${phoneNumber.number}`, '_self');
      return;
    }

    trackCustomEvent({ event: callStart.event!, context: callStart.context });
    setPhoneNumber({
      ...phoneNumber,
      isLoading: true,
    });
    const payload = {
      metaData: {
        trackingId: getClientUserId(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        vehicle_uuid: vehicleId,
        leadSource: getSource(tenant, 'web'),
        gclid: searchParams?.gclid,
        utmSource: searchParams?.utm_source,
        utmMedium: searchParams?.utm_medium,
        utmCampaign: searchParams?.utm_campaign,
        cItemId: searchParams?.cItemId,
        userId: userId,
        msclkId: searchParams?.msclkid,
        fbc: facebookClickIdValue,
        fbp: facebookPixelValue,
      },
    };

    try {
      const result = await loadGoogleRecaptcha(locale);

      if (result) {
        try {
          const data = await generatePhoneNumber(locale, payload);

          // Criteo Pixel Synchronization Script
          if (locale === 'uk' && tenant !== 'rac') {
            const trackingId = data.trackingId;
            const img = document.createElement('img');
            img.setAttribute(
              'src',
              `//gum.criteo.com/sync?c=69&a=1&r=1&u=https%3A%2F%2Fanalytics.freespee.com%2Fexternal%2Fc_pixel%3Fcriteoid%3D%40USERID%40%26mp_id%3D${trackingId}`,
            );
            img.setAttribute('style', 'position: absolute; height: 0px; width: 0px;');
            document.head.appendChild(img);
          }

          if (window.innerWidth < 768) {
            const callSubmitEvent = createEventWithCallNumber(
              vehicleId!,
              phoneNumber.number,
              ctaLocation,
            );
            trackCustomEvent({ event: callSubmitEvent!, context: callSubmit.context });
          }
          setPhoneNumber({
            ...phoneNumber,
            number: data?.displayNumber,
            isLoading: false,
          });
          setDealerNumber(data?.displayNumber);
          if (isMobileApp) {
            sendNativeMessage({ triggerAction: { action: 'call', payload: data?.displayNumber } });
            return;
          }
          if (window.innerWidth > 768) {
            setIsNumberVisible(true);
          }
        } catch (error) {
          setPhoneNumber({
            ...phoneNumber,
            isLoading: false,
            isError: true,
          });
        }
      }
    } catch (error) {
      // Displays error if there is some issue with Captcha script loading

      openModal(
        <StatusDisplay
          messages={{
            description: strings.leadFormError,
          }}
          type="error"
        />,
        {
          header: strings[ctaConfig.labelId],
          modalClassName: styles.modalContainer,
        },
      );
    }
  };

  const handleCallbackClick = () => {
    setLeadFormId('request-callback');
    const {
      leadStart: { event, context },
    } = generateLeadTrackingData({
      searchParams,
      userId,
      formState,
      leadFormId: 'request-callback',
      leadId: '',
      listingId: vehicleId,
      vehicle,
      ctaLocation: 'pdp',
      leadAction: 'start',
    });
    trackCustomEvent({ event, context });
    openModal(<LeadForm strings={strings} locale={locale} plp />, {
      header,
      modalClassName: leadFormStyles.modalContainer,
      modalBodyClassName: leadFormStyles.modalBodyClassName,
    });
  };
  const openingTimes = vehicle?.dealer?.openingTimes || [];
  const shouldShowCta = ctaConfig.shouldShowCta({ openingTimes }) as boolean;

  const hasButtonText = ctaConfig.showButtonText({ isSticky, isVsp, isEcom });

  const ctaText = () => {
    if (hasReserveNow && isSticky && isWindowDefined && window.innerWidth < 768) {
      return '';
    }

    if (isWindowDefined && window.innerWidth > 768) {
      if (isNumberVisible && !isInvestorStockLayout) {
        return dealerNumber;
      }
      if (isInvestorStockLayout) {
        return strings.call;
      }
    }

    if (hasButtonText) {
      return isInvestorStockLayout ? strings.call : strings[ctaConfig.labelId];
    }

    return '';
  };

  if (!shouldShowCta) return null;

  const isFr = locale === 'fr';

  if (ctaConfig.requestCallback(dealerNumberError, openingTimes)) {
    return (
      <>
        {ctaConfig.shouldShowPhoneNumberError(dealerNumberError, isSticky) && (
          <div className={styles.error}>{strings.callDealerError}</div>
        )}
        <Button
          leftIcon={ctaConfig.requestCallbackLeftIcon(isSticky, isEcom, hasReserveNow, isVsp)}
          variant={`${locale === 'fr' && !isEcom ? 'contained' : 'outlined'}`}
          onClick={handleCallbackClick}
          className={`${locale === 'fr' && isSticky ? styles.topPriority : ''}`}
          loading={false}
          disabled={disabled}
        >
          {ctaConfig.requestCallbackButtonLabel(strings, isSticky, isEcom, hasReserveNow)}
        </Button>
      </>
    );
  }

  if (!dealerNumberError)
    return (
      <>
        <Button
          leftIcon={
            (isSticky && isEcom && !isVsp && !phoneNumber.isLoading) ||
            isNumberVisible ||
            (hasReserveNow && isSticky)
              ? ctaConfig.buttonIcon
              : null
          }
          dataTestId="callDealerButton"
          disabled={disabled}
          variant={ctaConfig.variant({ isEcom, isFr })}
          loading={phoneNumber.isLoading}
          onClick={handleClick}
          className={`${locale === 'fr' && isSticky ? styles.topPriority : ''}`}
        >
          {ctaText()}
        </Button>
      </>
    );
};

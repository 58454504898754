import { useState } from 'react';

import { preApprovalConfigAtom } from '@core/Atoms/Config/PreApprovalConfig.atom';
import { localeAtom } from '@core/Atoms/Locale/Locale.atom';
import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { currentVehicleIdAtom } from '@core/Atoms/Vehicle/CurrentVehicleId.atom';
import { PreApprovalConfig } from '@core/Entities/Config/PreApprovalConfig.entity';
import { useGetRoot } from '@core/Hooks/Search/UseGetRoot';
import { fetchJson } from '@core/Utils/Fetch/FetchJson';
import { calculationAtom, calculationWithStorageAtom } from '@fincalc/Atoms/Calculation.atom';
import { finCalcInteractedWithAtom } from '@fincalc/Atoms/FinCalcInteractedWith.atom';
import Button from '@gds/Button/Button';
import { Warning } from '@gds/Icons/Paths/Warning';
import { Typography } from '@gds/Typography/Typography';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';
import { closeModalAtom } from '@modal/Atoms/Modal.atom';
import { useModal } from '@modal/Hooks/UseModal';
import { userProfileAtom } from '@my-account/Atoms/UserProfile.atom';
import { useApiData } from '@my-account/Utils/CreateApiDataAtom';
import { generateCheckoutStartTrackingData } from '@tracking/LeadCTA.tracking';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/navigation';

import { leadFormIdAtom } from 'Atoms/App/Pdp/LeadFormId.atom';

import { PostSignup } from '../StatusDisplay/PostSignup';

import styles from './ReturnAction.module.css';

export const ReturnAction = ({
  messages,
  returnToPage = false,
}: {
  returnToPage?: boolean;
  messages: Record<string, string>;
}) => {
  const router = useRouter();
  const root = useGetRoot();
  const pathname = window.location.pathname;
  const { closeModal } = useModal();
  const closeModalAtoms = useSetAtom(closeModalAtom);
  const handleBtnClick = () => {
    if (returnToPage || pathname.endsWith('/autos')) {
      closeModalAtoms();
    } else if (window.history.length <= 2) {
      router.replace(`/${root}`);
    } else {
      router.back();
    }
    // TODO: pass the query params to route with same make, same model and price +/- 15% of the vehicle
  };
  const isLeasingUrl = pathname.endsWith('/leasing');
  const vehicle = useAtomValue(currentVehicleAtom)!;
  const [hasFinancePreApprovalError, setHasFinancePreApprovalError] = useState(false);
  const [isFinancePreApprovalLoading, setIsFinancePreApprovalLoading] = useState(false);
  const flags = useAtomValue(flagsAtom);
  const setPreApprovalConfig = useSetAtom(preApprovalConfigAtom);
  const setCurrentFinanceVehicle = useSetAtom(calculationWithStorageAtom);
  const calculation = useAtomValue(calculationAtom)!;
  const listingId = useAtomValue(currentVehicleIdAtom);
  const leadFormId = useAtomValue(leadFormIdAtom);
  const hasFinancePreApproval = flags.financePreApproval;
  const eligibleProducts = vehicle?.eligibleProducts;
  const isPreapprovalVehicle = eligibleProducts?.includes('PREAPPROVAL_ANY_RCI');
  const userId = useAtomValue(userIdAtom);
  const formState = useAtomValue(formStateAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const locale = useAtomValue(localeAtom);
  const isFinCalcInteractedWith = useAtomValue(finCalcInteractedWithAtom);
  const hasError = calculation === null || !!calculation?.errorCode;
  const hasCaptiveError = calculation?.errorCode === 'CAPTIVE_SERVER_ERROR';

  const { data: userProfile } = useApiData(userProfileAtom);

  const shouldShowSignup = !userProfile && flags.myAccount;

  const isPreApprovalEligible =
    isFinCalcInteractedWith && hasFinancePreApproval && isPreapprovalVehicle && locale === 'fr';
  const hasPreApprovalCta = isPreApprovalEligible && !hasError && !hasCaptiveError;
  const getFinancePreApprovalConfig = async () => {
    setIsFinancePreApprovalLoading(true);
    try {
      const data: PreApprovalConfig = await fetchJson(
        `${process.env.NEXT_PUBLIC_COMMON_API_URL}/i15-pre-approval/config/${listingId}`,
        {
          method: 'GET',
          headers: {
            'X-Heycar-tenant': 'fr',
          },
        },
      );

      if (data) {
        setPreApprovalConfig(data);
        setCurrentFinanceVehicle(calculation);
        router.replace(`/fr/checkout/${vehicle?.heycarId}/pre-approval/about-you`);
        setIsFinancePreApprovalLoading(false);
      }
    } catch (error) {
      console.error(error);
      setHasFinancePreApprovalError(true);
      setIsFinancePreApprovalLoading(false);
    }
  };

  const handlePreApprovalClick = () => {
    getFinancePreApprovalConfig();
  };

  const handleClickCheckout = () => {
    const {
      checkoutStart: { event, context },
    } = generateCheckoutStartTrackingData({
      searchParams,
      userId,
      formState,
      listingId,
      vehicle,
      leadFormId: 'pre-approval',
      ctaLocation: 'pdp',
    });
    trackCustomEvent({ event, context });
    handlePreApprovalClick();
    closeModal();
  };

  if (shouldShowSignup && !hasPreApprovalCta) return <PostSignup source={leadFormId} />;

  return (
    <>
      <div>
        {!isLeasingUrl && <Button onClick={handleBtnClick}>{messages.backButtonMessage}</Button>}
      </div>

      {hasPreApprovalCta && (
        <div className={styles.preApprovalCard} data-variant="info">
          <div className={styles.status}>
            <Typography variant="h5">{messages.preApprovalTitle}</Typography>
            <Typography variant="body3">{messages.preApprovalSubTitle}</Typography>
          </div>

          <div className={styles.buttonGroup}>
            <Button
              variant="outlined"
              data-test-id="exit-button"
              className={styles.button}
              onClick={() => closeModal(true)}
            >
              {messages.thankYouCloseButton}
            </Button>{' '}
            <div className={styles.preApprovalButtonWrapper}>
              {hasFinancePreApprovalError ? (
                <div>
                  <div className={styles.preApprovalErrorIcon}>
                    <Warning />
                  </div>
                  <Typography variant="body4" Component="p">
                    {messages.financePreApprovalError}
                  </Typography>
                </div>
              ) : (
                <Button
                  dataTestId="pre-approved"
                  className={styles.button}
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={isFinancePreApprovalLoading}
                  onClick={handleClickCheckout}
                >
                  {messages.preApprovalStartButton}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

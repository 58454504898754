'use client';

import { closeModalAtom, openModalAtom } from '@modal/Atoms/Modal.atom';
import { ModalProps } from '@modal/Entities/Modal.entity';
import { atom } from 'jotai';

import { StatusDisplay } from 'Components/LeadForms/StatusDisplay/StatusDisplay';

import { LeadType } from 'Components/Pdp/LeadCTAs/LeadCTAs.entity';
import { leadCtasConfig } from 'Components/Pdp/LeadCTAs/LeadCtaConfig';

import { leadFormIdAtom } from './LeadFormId.atom';
import { leadFormStringsAtom } from './LeadFormStrings.atom';

import statusDisplayStyles from 'Components/LeadForms/StatusDisplay/StatusDisplay.module.css';

export const openErrorModalAtom = atom(null, (get, set, modalProps?: ModalProps) => {
  const strings = get(leadFormStringsAtom);
  const leadFormId = get(leadFormIdAtom) as LeadType;

  // @ts-expect-error - onErrorModalAction is optional
  const errorModalAction = leadCtasConfig[leadFormId]?.onErrorModalAction?.(strings);
  // @ts-expect-error - errorModalMessages is optional
  const messages = leadCtasConfig[leadFormId].errorModalMessages?.(strings);

  set(closeModalAtom);
  set(
    openModalAtom,
    <StatusDisplay
      messages={{
        description: messages?.description ?? strings.errorModalMessageDescription,
        headline: messages?.headLine ?? strings.errorModalMessageHeadline,
      }}
      type="error"
    />,
    {
      header: strings.successModalHeader,
      modalAction: errorModalAction ? errorModalAction : <></>,
      modalClassName: statusDisplayStyles.statusDisplayWrapper,
      footerClassName: statusDisplayStyles.returnActionCta,
      modalContentClassName: statusDisplayStyles.statusDisplayBodyWrapper,
      ...modalProps,
    },
  );
});
